<template>
  <div>
    <b-row>
      <b-col xl="5" lg="12">
        <vue-sticky-sidebar :topSpacing="12" :bottomSpacing="12">
          <div class="card overflow-hidden mb-0 mt-0">
            <div class="card-body p-0">
              <div class="position-relative" :class="$style.cover" :style="`background-image: url(${form.banner_media ? form.banner_media.url : '/resources/images/content/photos/1.jpeg'})`"></div>
              <div class="d-flex flex-column flex-sm-row">
                <div class="text-center pl-4 pr-4 flex-shrink-0" :class="$style.user">
                  <div
                    class="kit__utils__avatar kit__utils__avatar--rounded kit__utils__avatar--size84 border border-5 border-white d-inline-block"
                  >
                    <img :src="form.image_media ? `${form.image_media.url}?tr=w-300,h-300,c-maintain_ratio` : '/resources/images/avatars/avatar-2.png'" :alt="user.full_name" />
                  </div>
                  <div class="font-weight-bold font-size-18">
                    {{ user.full_name }}
                    <div :class="`${$style.indicator} ${user.active ? 'bg-success' : 'bg-warning'}`" class="d-inline-block rounded-circle"></div>
                  </div>
                  <div class="text-uppercase text-muted font-size-12 mb-3">{{ user.role.name }}</div>
                </div>
                <p class="pt-3 pr-3 mb-0">
                  {{ user.bio }}
                </p>
              </div>
              <div class="px-4">
                <a-divider />
                <div class="w-100">
                  <b-row>
                    <b-col lg="6">
                      <h6 class="small text-muted">Email</h6>
                      <p class="text-dark">
                        <a :href="`mailto:${user.email}`">{{ user.email }}</a>
                      </p>
                    </b-col>
                    <b-col lg="6">
                      <h6 class="small text-muted">Phone Number</h6>
                      <p class="text-dark">
                        <a :href="`tel:${user.phone}`">{{ user.phone }}</a>
                      </p>
                    </b-col>
                  </b-row>
                  <a-divider>Portals</a-divider>
                  <b-row>
                    <b-col lg="12">
                      <ul v-if="user.portals.length" class="list-unstyled mb-4 pb-2">
                        <li v-for="portal in user.portals" :key="portal.id" :class="$style.item">
                          <div class="bg-success mr-3" :class="`${$style.separator} ${portal.suspended ? 'bg-warning' : 'bg-success'}`" />
                          <div>
                            <div>{{ portal.title }}</div>
                            <div class="text-muted">{{ portal.domain }}</div>
                          </div>
                        </li>
                      </ul>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>
          </div>
        </vue-sticky-sidebar>
      </b-col>
      <b-col xl="7" lg="12">
        <div class="card mb-0 mt-0">
          <div class="card-header card-header-flex flex-column">
            <div class="d-flex flex-wrap border-bottom pt-3 pb-4 mb-3">
              <div class="mr-5">
                <div class="text-dark font-size-18 font-weight-bold">{{ user.full_name }}</div>
                <div class="text-gray-6">@{{ user.username }}</div>
              </div>
              <div class="mr-5 text-center">
                <div class="text-dark font-size-18 font-weight-bold">{{ postsCount }}</div>
                <div class="text-gray-6">Posts</div>
              </div>
              <div class="mr-5 text-center">
                <div class="text-dark font-size-18 font-weight-bold">{{ user.portals.length }}</div>
                <div class="text-gray-6">Portals</div>
              </div>
            </div>
            <a-tabs v-model="activeKey" class="kit-tabs kit-tabs-bold" @change="callback">
              <a-tab-pane tab="Timeline" key="timeline" />
              <a-tab-pane tab="Settings" key="settings" />
              <a-tab-pane tab="Change Password" key="password" />
            </a-tabs>
          </div>
          <div class="card-body">
            <div v-if="activeKey === 'timeline'">
              <div v-if="postData.length">
                <div v-for="post in postData" :key="post.id" class="d-flex flex-nowrap align-items-start mb-4 pb-2 border-light border-bottom">
                  <div class="mr-4 flex-shrink-0 align-self-start kit__utils__avatar kit__utils__avatar--size64">
                    <img :src="post.createdBy.imgMedia ? post.createdBy.imgMedia.url : '/resources/images/avatars/avatar-2.png'" :alt="post.createdBy.full_name" />
                  </div>
                  <div class="flex-grow-1">
                    <div>
                      <div class="d-flex flex-wrap mb-2">
                        <div class="mr-auto">
                          <div class="text-gray-6">
                            <span class="text-dark font-weight-bold">{{ (user.id === post.createdBy.id) ? 'You' : post.createdBy.full_name }}</span> posted on <span class="text-dark font-weight-bold">{{ post.portals.length ? post.portals[0].title : 'unknown' }}</span>
                          </div>
                          <div class="text-muted small">
                            <vue-moments-ago prefix="" :date="post.published_at" lang="en"></vue-moments-ago>
                          </div>
                        </div>
                        <div class="nav-item dropdown">
                          <a-dropdown placement="bottomRight" :trigger="['click']">
                            <a class="nav-link px-0 pt-sm-0" href="javascript: void(0);"><span class="fe fe-more-vertical"></span></a>
                            <a-menu slot="overlay">
                              <a-menu-item>
                                <a :href="post.url" target="_blank">Continue Reading <span class="fe fe-external-link small"></span></a>
                              </a-menu-item>
                              <a-menu-item>
                                <a href="javascript:;" v-clipboard="post.url">Copy Link</a>
                              </a-menu-item>
                              <a-menu-divider />
                              <a-menu-item v-if="user.id === post.createdBy.id">
                                <router-link :title="`Edit ${post.title}`" :to="{ path: `posts/edit/${post.id}`}">Edit</router-link>
                              </a-menu-item>
                              <a-menu-item v-if="user.id === post.createdBy.id">
                                <a class="text-danger" :title="`Delete ${post.title}`" @click.prevent="showDeleteConfirm(post)" href="javascript:;">Delete</a>
                              </a-menu-item>
                              <a-menu-item v-if="!(user.id === post.createdBy.id)">
                                <a href="javascript:;"><span class="fe fe-repeat"></span> Repost</a>
                              </a-menu-item>
                            </a-menu>
                          </a-dropdown>
                        </div>
                      </div>
                      <div class="mb-3">
                        <a :href="post.url" target="_blank" class="card overflow-hidden mb-2">
                          <div class="card-body p-0">
                            <div class="row no-gutters">
                              <div class="col-3 pr-0" v-if="post.imageMedia.length">
                                <img
                                  :src="`${post.imageMedia[0].url}?tr=w-178,h-178`"
                                  :srcset="`${post.imageMedia[0].url}?tr=w-178,h-178 1x, ${post.imageMedia[0].url}?tr=w-356,h-356 2x`"
                                  class="w-100" alt="Flowers" />
                              </div>
                              <div :class="post.imageMedia.length ? 'col-9 py-2 pr-3 pl-2' : 'col-12 p-3'">
                                <span v-if="post.portals.length" class="small d-block mb-2">{{ post.portals[0].domain }}</span>
                                <h5 class="title h6">{{ post.title }}</h5>
                                <div class="text-muted excerpt small" v-html="post.excerpt"></div>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <infinite-loading @infinite="fetch"></infinite-loading> -->
              </div>
              <p v-else class="text-muted">
                No post yet.
              </p>
            </div>
            <!-- Personal Information -->
            <a-form-model v-if="activeKey === 'settings'" :colon="false" @submit.prevent="saveProfile" spellcheck="false" ref="form" :model="form" :rules="rules">
              <h5 class="text-black mt-2 mb-3">
                <strong>Personal Information</strong>
              </h5>
              <b-row>
                <b-col>
                  <a-form-model-item label="Bio" name="bio" prop="bio">
                    <a-textarea v-model="form.bio" />
                  </a-form-model-item>
                </b-col>
              </b-row>
              <div class="row">
                <div class="col-lg-6">
                  <a-form-model-item label="Full Name" name="full_name" prop="full_name">
                    <a-input placeholder="Full Name" v-model="form.full_name" />
                  </a-form-model-item>
                </div>
                <div class="col-lg-6">
                  <a-form-model-item label="Username" name="username" prop="username">
                    <a-input placeholder="Username" v-model="form.username" />
                  </a-form-model-item>
                </div>
                <div class="col-lg-6">
                  <a-form-model-item label="Email" name="email" prop="email">
                    <a-input placeholder="Email" v-model="form.email" />
                  </a-form-model-item>
                </div>
                <div class="col-lg-6">
                  <a-form-model-item label="Phone Number" name="phone" prop="phone">
                    <a-input v-model="form.phone" />
                  </a-form-model-item>
                </div>
                <div class="col-lg-6">
                  <a-form-model-item label="Twitter" name="twitter_account" prop="twitter_account">
                    <a-input v-model="form.twitter_account" />
                  </a-form-model-item>
                </div>
                <div class="col-lg-6">
                  <a-form-model-item label="LinkedIn" name="linkedin_account" prop="linkedin_account">
                    <a-input v-model="form.linkedin_account" />
                  </a-form-model-item>
                </div>
                <div class="col-lg-6">
                  <a-form-model-item label="Instagram" name="instagram_account" prop="instagram_account">
                    <a-input v-model="form.instagram_account" />
                  </a-form-model-item>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <h5 class="text-black mt-2 mb-3">
                    <strong>Profile Avatar</strong>
                  </h5>
                  <div class="media__preview" v-if="Object.keys(form.image_media).length">
                    <button @click.prevent="removeMedia" class="media__remove">
                      <i class="fe fe-x"></i>
                    </button>
                    <img
                      :src="`${form.image_media.url}?tr=w-322`"
                      :srcset="`${form.image_media.url}?tr=w-322 1x, ${form.image_media.url}?tr=w-644 2x`"
                      :alt="form.image_media.alt"
                    />
                  </div>
                  <b-button variant="info" @click.prevent="mediaFinderOpen" block>{{
                    Object.keys(form.image_media).length ? "Change Media" : "Add Media"
                  }}</b-button>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <h5 class="text-black mt-2 mb-3">
                    <strong>Profile Banner</strong>
                  </h5>
                  <div class="media__preview" v-if="Object.keys(form.banner_media).length">
                    <button @click.prevent="removeBanner" class="media__remove">
                      <i class="fe fe-x"></i>
                    </button>
                    <img
                      :src="`${form.banner_media.url}?tr=w-322`"
                      :srcset="`${form.banner_media.url}?tr=w-322 1x, ${form.banner_media.url}?tr=w-644 2x`"
                      :alt="form.banner_media.alt"
                    />
                  </div>
                  <b-button variant="info" @click.prevent="bannerFinderOpen" block>{{
                    Object.keys(form.banner_media).length ? "Change Banner" : "Add Banner"
                  }}</b-button>
                </div>
              </div>
              <div class="form-actions mt-0">
                <a-button type="primary" htmlType="submit" @click.prevent="saveProfile">Save Profile</a-button>
              </div>
            </a-form-model>
            <!-- /Personal Information -->
            <a-form-model @submit.prevent="changePassword" :colon="false" spellcheck="false" ref="form" v-if="activeKey === 'password'" :model="password_form" :rules="password_rules">
              <h5 class="text-black mt-2 mb-3">
                <strong>Change Password</strong>
              </h5>
              <div class="row">
                <div class="col-lg-6">
                  <a-form-model-item label="Current Password">
                    <password default-class="ant-input" v-model="password_form.old_password" :disabled="passeordFormLoading" :show-password="showPassword" :badge="false" :toggle="true"/>
                  </a-form-model-item>
                </div>
                <div class="col-12"></div>
                <div class="col-lg-6">
                  <a-form-model-item label="New Password">
                    <password default-class="ant-input" v-model="password_form.password" :disabled="passeordFormLoading" :show-password="showPassword" :badge="false" :toggle="true"/>
                  </a-form-model-item>
                </div>
                <div class="col-lg-6">
                  <a-form-model-item label="Confirm New Password">
                    <password default-class="ant-input" placeholder="Confirm New Password" :disabled="passeordFormLoading" v-model="password_form.password_confirmation" :badge="false" :toggle="true" />
                  </a-form-model-item>
                </div>
              </div>
              <div class="form-actions mt-0">
                <a-button type="primary" htmlType="submit" @click.prevent="changePassword">Change Password</a-button>
              </div>
            </a-form-model>
          </div>
        </div>
      </b-col>
    </b-row>
    <!-- Media Finder for Avatar -->
    <b-modal
      hide-footer
      id="media-finder"
      size="xl"
      body-class="p-0 bg-light"
      centered
      title="Select Avatar"
    >
      <MediaFinder button-text="Choose Avatar" :current="[form.image_media]" v-on:insert="applyMedia" />
    </b-modal>
    <!-- Media Finder for Banner -->
    <b-modal
      hide-footer
      id="banner-finder"
      size="xl"
      body-class="p-0 bg-light"
      centered
      title="Select Banner"
    >
      <MediaFinder button-text="Choose Banner" :current="[form.banner_media]" v-on:insert="applyBanner" />
    </b-modal>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import VueMomentsAgo from 'vue-moments-ago'
import vueStickySidebar from 'vue-sticky-sidebar'
import Password from 'vue-password-strength-meter'
import MediaFinder from '../media/finder'

export default {
  data() {
    return {
      activeKey: 'timeline',
      postsCount: 0,
      postData: [],
      showPassword: false,
      passeordFormLoading: false,
      avatarLoading: false,
      imageUrl: '',
      rules: {
        email: [
          {
            required: true,
            message: 'Please input your email address',
          },
          {
            pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
            message: 'Wrong email format',
          },
        ],
        username: [
          {
            required: true,
            message: 'Please input your username',
          },
          {
            min: 5,
            message: 'Please input at least 5 characters',
          },
          {
            whitespace: true,
          },
          {
            validator: this.usernameValidator,
            message: 'Wrong username format',
          },
        ],
      },
      password_rules: {
        old_password: [
          {
            required: true,
            message: 'Please input your old password',
          },
        ],
        password: [
          {
            required: true,
            message: 'Please input your new password',
          },
        ],
        password_confirmation: [
          {
            required: true,
            message: 'Please input password confirmation',
          },
        ],
      },
    }
  },
  computed: {
    ...mapState(['user']),
    ...mapState('profile', {
      form: state => state.form,
      password_form: state => state.password_form,
    }),
    ...mapState('post', {
      posts: state => state.data,
    }),
    loading() {
      return this.$store.state.user.loading
    },
  },
  created() {
    const vm = this
    vm.fetch()
    vm.activeKey = vm.$route.query.t ? vm.$route.query.t : 'timeline'
    vm.$set(vm.form, 'full_name', vm.user.full_name)
    vm.$set(vm.form, 'username', vm.user.username)
    vm.$set(vm.form, 'bio', vm.user.bio)
    vm.$set(vm.form, 'email', vm.user.email)
    vm.$set(vm.form, 'phone', vm.user.phone)
    vm.$set(vm.form, 'twitter_account', vm.user.twitter_account)
    vm.$set(vm.form, 'linkedin_account', vm.user.linkedin_account)
    vm.$set(vm.form, 'instagram_account', vm.user.instagram_account)
    vm.$set(vm.form, 'image_media', (vm.user.imgMedia ? vm.user.imgMedia : {}))
    vm.$set(vm.form, 'banner_media', (vm.user.bannerMedia ? vm.user.bannerMedia : {}))
  },
  methods: {
    saveProfile() {
      const vm = this
      vm.$refs.form.validate((valid) => {
        if (valid) {
          vm.$store.dispatch('profile/UPDATE_PROFILE').then((res) => {
            vm.$notification.success({
              message: 'Profile Updated',
              description: 'Successfully Updated.',
            })
            vm.fetch()
          })
        } else {
          return false
        }
      })
    },
    changePassword() {
      const vm = this
      vm.$refs.form.validate((valid) => {
        if (valid) {
          vm.$store.dispatch('profile/UPDATE_PASSWORD').then((res) => {
            if (res.data.status) {
              vm.$notification.success({
                message: 'Password Updated',
                description: 'Successfully Updated.',
              })
            } else {
              if (res.data.length > 0) {
                vm.$notification.error({
                  message: 'Change Password Failed',
                  description: res.data[0].message,
                })
              } else {
                vm.$notification.error({
                  message: 'Change Password Failed',
                  description: 'Current password could not be verified!',
                })
              }
            }
            vm.$store.commit('profile/CLEAR_PASSWORD_FORM')
          }).catch(function() {
            vm.$notification.error({
              message: 'Change Password Failed',
              description: 'Current password could not be verified!',
            })
            vm.$store.commit('profile/CLEAR_PASSWORD_FORM')
          })
        } else {
          vm.$notification.error({
            message: 'Change Password Failed',
            description: 'Current password could not be verified!',
          })
          vm.$store.commit('profile/CLEAR_PASSWORD_FORM')
        }
      })
    },
    mediaFinderOpen() {
      const vm = this
      vm.$bvModal.show('media-finder')
    },
    applyMedia(media, medias) {
      const vm = this
      vm.$set(vm.form, 'image_media', media)
      vm.$set(vm.form, 'image_media_id', media.id)
      vm.$bvModal.hide('media-finder')
    },
    removeMedia() {
      const vm = this
      vm.$set(vm.form, 'image_media_id', null)
      vm.$set(vm.form, 'image_media', {})
    },
    bannerFinderOpen() {
      const vm = this
      vm.$bvModal.show('banner-finder')
    },
    applyBanner(media, medias) {
      const vm = this
      vm.$set(vm.form, 'banner_media', media)
      vm.$set(vm.form, 'banner_media_id', media.id)
      vm.$bvModal.hide('banner-finder')
    },
    removeBanner() {
      const vm = this
      vm.$set(vm.form, 'banner_media_id', null)
      vm.$set(vm.form, 'banner_media', {})
    },
    callback: function (key) {
      this.activeKey = key
    },
    fetch($state) {
      const vm = this
      vm.$store.dispatch('post/PUBLIC_FETCH', {
        createdBy: vm.user.full_name,
        page: vm.page,
      }).then((res) => {
        const output = vm.postData.concat(vm.posts)
        vm.postsCount = Number(res.data.total)
        vm.postData = output
        vm.page++
        if ($state) {
          if (res.data.page === res.data.lastPage) {
            $state.complete()
          } else {
            $state.loaded()
          }
        }
      })
    },
  },
  watch: {
    '$route.query.t': function(tab) {
      const vm = this
      vm.activeKey = tab || 'timeline'
    },
  },
  components: {
    VueMomentsAgo,
    'vue-sticky-sidebar': vueStickySidebar,
    Password,
    MediaFinder,
  },
}
</script>
<style lang="scss" module>
  @import './style.module.scss';
</style>
